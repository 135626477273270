<template>
  <div class="mt-5">
    <vs-textarea
      label
      counter="4096"
      :counter-danger.sync="counterDanger"
      @input="emitEvent(textElement)"
      v-model="textElement"
    />
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    value: {
      default: "",
    },
    update: {
      type: Function,
    },
  },
  data() {
    return {
      textElement: "",
      counterDanger: false,
    };
  },
  beforeMount() {
    if (this.value) {
      this.textElement = `${this.value}`;
    }
  },
  methods: {
    emitEvent(textElement) {
      this.$emit("change", true);
      this.$emit("update:value", textElement);
      this.update(this.number);
    },
  },
  watch: {
    counterDanger(newVal) {
      this.$emit("update:counterDanger", this.counterDanger);
    },
  },
};
</script>

<style>
</style>